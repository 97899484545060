import React from "react"

// UI
import SectionHero from "~components/SectionHero"
import SectionMediaFull from "~components/SectionMediaFull"

const InfoBarRow: React.FC<InfoBarRowProps> = ({ title, tags }: InfoBarRowProps) => {
  return (
    <div className="text-white flex flex-col lg:flex-row">
      <div className="flex items-center w-56 mb-2 lg:mb-0">
        <strong>{title}</strong>
      </div>
      <div className="flex flex-wrap gap-1">
        {tags.map((tags, index) => (
          <div
            key={index}
            className="border border-blue-500 rounded-full text-xs lg:text-sm py-1.5 lg:py-2 px-3 lg:px-3.5"
          >
            {tags}
          </div>
        ))}
      </div>
    </div>
  )
}

const CustomerHero: React.FC<CustomerHeroProps> = ({
  img,
  imgAlt,
  title,
  subtitle,
  productTags,
  teamTags,
}: CustomerHeroProps) => (
  <>
    <SectionHero title={title} subtitle={subtitle} level={1} buttons={false} />
    <SectionMediaFull
      img={img}
      imgAlt={imgAlt}
      className="mb-16 md:mb-20 lg:mb-32"
      infoBar={
        <div className="flex flex-col gap-4 py-6">
          <InfoBarRow title="Canopy Products Used" tags={productTags} />
          <InfoBarRow title="Teams Using Canopy" tags={teamTags} />
        </div>
      }
    />
  </>
)

interface InfoBarRowProps {
  tags: string[]
  title: string
}

interface CustomerHeroProps {
  img: string
  imgAlt: string
  productTags: string[]
  teamTags: string[]
  title: string | React.ReactNode
  subtitle: string | React.ReactNode
}

export default CustomerHero
