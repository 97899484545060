import React from "react"

// UI
import Layout from "~components/layout"
import SEO from "~components/seo"
import CustomerHero from "~content/customers/detail/Hero"
import CustomerIntro from "~content/customers/detail/Intro"
import CustomerVideo from "~content/customers/detail/Video"

// Media
import imgHeroTandym from "~images/customers/tandym/tandym-hero.jpg"

const CustomerTandym: React.FC = () => {
  return (
    <Layout>
      <SEO
        canonicalTag="https://canopyservicing.com/customers/tandym"
        description="Canopy Servicing is an API-first platform that is enabling the next generation of credit and lending products."
        title="Tandym Case Study | Canopy Servicing"
      />

      <CustomerHero
        title="Launching Revolving Credit on a Closed Loop Infrastructure"
        subtitle="Tandym, the first fintech company to enable e-commerce businesses to quickly and easily offer their own, next-gen private label credit cards, needed a modern lending architecture. In launching a revolving credit product on a closed loop infrastructure, they chose Canopy as their flexible loan management system to help them reach the next level."
        productTags={["Ledger", "DataDirect", "LoanLab", "Webhooks"]}
        teamTags={["Operations", "Product", "Credit & Analytics", "Engineering", "Legal & Compliance"]}
        img={imgHeroTandym}
        imgAlt="Novo Case Study Hero Image"
      />

      <CustomerIntro
        title="Innovative, Frictionless Digital Credit for E-Commerce"
        body={
          <>
            As the first private-label credit card company empowering merchants of any size to offer a branded digital
            credit card, Tandym is on a mission to help merchants grow their business. The innovative platform is
            designed to enable fast, seamless integration with retailers to cut processing fees and power turnkey
            loyalty programs for customers.
            <br />
            <br />
            To build and maintain trust with their own clients, the Tandym team worked closely with Canopy to launch
            their revolving credit product with an emphasis on dynamic minimum payment calculations, reliable
            delinquency reports, and a frictionless experience for payment integration.
          </>
        }
      />

      <CustomerVideo
        title={<span className="text-yellow-300">Must-Have Features for a Loan Management System</span>}
        subtitle={
          <span className="text-gray-300">
            Post origination, one of the key features the Tandym team considered in their product development was a
            seamless, consistent servicing experience that matched expectations set during the onboarding flow. For
            instance, making repayment as easy as possible for the end customer.
          </span>
        }
        videoID="-PBraJKf_Lg"
        color="dark"
      />
    </Layout>
  )
}

export default CustomerTandym
