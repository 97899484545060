import * as React from "react"

// UI
import Section from "~components/Section"
import Headline from "~ui/Headline"

const CustomerIntro: React.FC<CustomerIntroProps> = ({ title, body }: CustomerIntroProps): JSX.Element => {
  return (
    <Section className="mb-16 md:mb-20 lg:mb-32">
      <Headline level={2} data-sal="slide-up">
        {title}
      </Headline>
      <p className="md:text-lg leading-normal" data-sal="slide-up" data-sal-delay="200">
        {body}
      </p>
    </Section>
  )
}

interface CustomerIntroProps {
  title: string | React.ReactNode
  body: string | React.ReactNode
}

export default CustomerIntro
