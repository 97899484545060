import * as React from "react"

// UI
import SectionAlt from "~components/SectionAlt"
import SectionMediaFull from "~components/SectionMediaFull"

const CustomerVideo: React.FC<CustomerVideoProps> = ({
  className,
  color = "light",
  title,
  subtitle,
  videoID,
}: CustomerVideoProps) => {
  return (
    <SectionAlt className={className} color={color}>
      <SectionMediaFull
        title={title}
        subTitle={subtitle}
        video={
          <iframe
            src={`https://www.youtube.com/embed/${videoID}?rel=0`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
            allowFullScreen
          ></iframe>
        }
        className="mb-8"
      />
    </SectionAlt>
  )
}

interface CustomerVideoProps {
  className?: string
  color?: "default" | "primary" | "dark" | "light"
  title: React.ReactNode | string
  subtitle: React.ReactNode | string
  videoID: string
}

export default CustomerVideo
