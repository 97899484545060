import React from "react"

const SectionAlt = ({ children, color = "default", className }: SectionAltProps): JSX.Element => {
  return (
    <div
      className={`
        ${
          color === "default"
            ? "bg-yellow-100 dark:bg-yellow-1200 border border-yellow-100 dark:border-yellow-1100"
            : ""
        } 
        ${color === "primary" ? "bg-sky-50" : ""} 
        ${color === "dark" ? "bg-black dark:bg-gray-900" : ""} 
        ${color === "light" ? "" : ""} 
        py-16 md:py-20 lg:py-28 xl:pb-32
        ${className}
      `}
    >
      <div className="container mx-auto">{children}</div>
    </div>
  )
}

interface SectionAltProps {
  children?: React.ReactNode
  color?: "default" | "primary" | "dark" | "light"
  className?: string
}

export default SectionAlt
