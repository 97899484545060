import React from "react"

// UI
import Button from "~ui/Button"
import Headline from "~ui/Headline"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"

const SectionHero: React.FC<SectionHeroProps> = ({
  buttons = true,
  children,
  className,
  fullWidth = true,
  level = 1,
  narrow = false,
  title,
  titleTextSize,
  subtitle,
}: SectionHeroProps) => {
  const baseContainerClasses = "py-12 md:py-20 lg:py-24 xl:py-28"
  const narrowContainerClasses = "lg:px-12"
  const fullWidthClasses = "lg:w-3/4"

  return (
    <div className={`${baseContainerClasses} ${narrow && narrowContainerClasses} ${className}`}>
      <div className="mx-auto sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl px-12">
        <div className={`lg:px-6 2xl:px-8 ${!fullWidth && fullWidthClasses}`}>
          <Headline level={level} textSize={titleTextSize} data-sal="slide-up" data-sal-delay="300">
            {title}
          </Headline>
          {subtitle && (
            <p className="lg:text-lg lg:max-w-3xl" data-sal="slide-up" data-sal-delay="500">
              {subtitle}
            </p>
          )}
          {buttons && (
            <div className="flex flex-col sm:flex-row mt-8 lg:mt-10" data-sal="slide-up" data-sal-delay="700">
              <Button style="primary" to="/start" icon={faArrowRight} size="large" className="mb-2 sm:mb-0 sm:mr-4">
                Start Now
              </Button>
              <Button to="/platform" size="large">
                Learn More
              </Button>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

interface SectionHeroProps {
  buttons?: boolean
  children?: React.ReactNode
  className?: string
  fullWidth?: boolean
  level?: 1 | 2 | 3 | 4 | 5
  narrow?: boolean
  title: string | React.ReactNode
  titleTextSize?: string
  subtitle?: string | React.ReactNode
}

export default SectionHero
